<template>
  <div>
      <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
          <el-button type="success" :disabled="loading" @click="onPressUpdateCollection">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <custom-header :title="'Collection засах'"/>
        <el-form label-position="top" :model="collectionData" ref="collectionData" v-loading="loading">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                </div>
                <page-items :data="collectionData.items" @update:data="dataUpdate" :updateFunction="dataUpdate"></page-items>
              </div>
            </el-col>
            <div class="panel-item">
              <el-col :span="8">
                <el-row :gutter="20">
                  <el-button
                    style="width: 100%; margin-top: 20px" type="primary" @click="addBanner">Баннер нэмэх</el-button>
                </el-row>
                <el-form>
                  <div class="panel" style="margin-top: 30px">
                    <div class="panel-item">
                      <el-row :gutter="20" class="mt20 pl10">
                        <el-col :span="12">
                          <h4>Монгол нэр</h4>
                          <el-input
                            type="text" size="mini" placeholder="Монгол нэр" v-model="collectionData.name_mon"></el-input>
                        </el-col>
                        <el-col :span="12">
                          <h4>Англи нэр</h4>
                          <el-input
                            type="text" size="mini" placeholder="Англи нэр" v-model="collectionData.name_eng"></el-input>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20" class="mt20 pl10">
                        <el-col :span="12">
                          <h4>Гарчиг</h4>
                          <el-input
                            type="text" size="mini" placeholder="Гарчиг оруулах" v-model="collectionData.title"></el-input>
                        </el-col>
                        <el-col :span="12">
                          <h4>Slug</h4>
                          <el-input
                            type="text" size="mini" placeholder="Slug оруулах..." v-model="collectionData.slug"></el-input>
                        </el-col>
                      </el-row>
                      <el-row :gutter="35" class="mt20 pl10">
                      <el-col :span="12">
                        <h4>Төлөв</h4>
                        <el-switch
                        v-model="collectionData.status"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-value="active"
                        inactive-value="inactive"
                        active-text="Идэвхтэй"
                        inactive-text="Идэвхгүй">
                        </el-switch>
                      </el-col>
                        <el-col :span="12">
                          <h4>Эрэмбэ</h4>
                          <el-input
                            type="text" size="mini" placeholder="Эрэмбэ оруулах..." v-model="collectionData.sort"></el-input>
                        </el-col>
                      </el-row>
                       <el-row :gutter="20" class="mt20 pl10">
                    <el-col :span="8">
                      <el-form-item label="Эхлэх огноо">
                        <el-date-picker
                          size="small"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          type="datetime"
                          v-model="collectionData.start_date"
                          placeholder="Огноо сонгоно уу"
                          >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="6">
                      <el-form-item label="Зураг">
                        <el-upload
                          list-type="picture-card"
                          accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                          action=""
                          :auto-upload="false"
                          :file-list="tempImage"
                          :on-remove="(file) => handleRemove(file)"
                          :on-change="(file) => getFile(file)">
                        <i class="el-icon-plus"></i>
                        </el-upload>
                        <loader :active="uploading"/>
                      </el-form-item>
                    </el-col>
                       </el-row>
                       <div class="mt20 pl10">
                         <el-checkbox v-model="checked">Дуусах огноо сонгох</el-checkbox>
                       </div>
                       <el-row :gutter="20" class="mt20 pl10">
                    <el-col :span="8">
                      <div v-if="checked">
                      <el-form-item label="Дуусах огноо">
                        <el-date-picker
                          size="small"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="collectionData.end_date"
                          type="datetime"
                          placeholder="Огноо сонгоно уу">
                        </el-date-picker>
                      </el-form-item>
                      </div>
                    </el-col>
                  </el-row>
                    </div>
                  </div>
                </el-form>
              </el-col>
            </div>
          </el-row>
        </el-form>
        <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-button class="text-left" type="danger" @click="dialogVisibles = true">Устгах</el-button>
              </el-col>
              <el-col :span="12" class="text-right">
                <el-button style="margin-top: 20px" type="defult" @click="back">Буцах</el-button>
                <el-button type="success" @click="onPressUpdateCollection"
                >Шинэчлэх</el-button>
              </el-col>
            </el-row>
          </div>
    <el-dialog
      title="Баннер нэмэх"
      :visible.sync="dialogVisible"
      width="30%">
      <el-form label-position="right" label-width="100px" :model="item">
        <el-form-item label="Name">
            <el-input size="smill" placeholder="нэрээ оруулна уу..." v-model="item.name_mon"></el-input>
        </el-form-item>
        <el-form-item v-if="item.type === 'banner'" label="data">
          <el-select
          value-key="id" remote :remote-method="getBanner"
          v-model="item.data_id"
          filterable
          placeholder="сонгох"
          size="smill"
        class="block" @change="selectedBanner(banners)">
            <el-option
            v-for="(banner, index) in banners"
            :key="index" :label="banner.banner_code"
            :value="banner.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">буцах</el-button>
        <el-button type="primary" @click="addBannerItem">нэмэх</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/collectionList">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>

<el-dialog
  :title="collectionData.name_mon"
  :visible.sync="dialogVisibles"
  width="30%">
  <span><strong>{{collectionData.name_mon}}</strong> хуудсыг устгахдаа итгэлтэй байна уу?</span>
  <span slot="footer" class="dialog-footer">
    <el-button type="defult" @click="dialogVisibles = false">Болих</el-button>
    <el-button type="danger" @click="deleteCollections">Устгах</el-button>
  </span>
</el-dialog>
  </div>
</template>
<script>
import pageItems from '../Collection/components/pageItems.vue'
import CustomHeader from '../../../components/common/customHeader'
import services from '../../../helpers/services'
import { handlers } from '../../../helpers/custom'
import Loader from '../../../components/common/loader'
export default {
  name: 'addCollection',
  components: {
    CustomHeader,
    pageItems,
    Loader
  },
  watch: {
    'collectionData.items': function (val) {
      this.collectionData.items.forEach((item, index) => {
        this.collectionData.items[index].sort = index
      })
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getOneCollections(this.$route.params.id)
    }
    this.getBanner()
  },
  data () {
    return {
      uploading: false,
      tempImage: [],
      confirmLeaveForm: false,
      dialogVisible: false,
      dialogVisibles: false,
      checked: false,
      item: {},
      banners: [],
      collectionData: {
        collection_pic: '',
        name_eng: '',
        name_mon: '',
        start_date: '',
        end_date: null,
        slug: '',
        items: [{
          data_id: '',
          type: ''
        }],
        created_at: '',
        collection_id: '',
        sort: 0,
        status: '',
        deleted_at: '',
        is_deleted: ''
      },
      errorArray: [],
      loading: false
    }
  },
  methods: {
    getFile (file) {
      handlers.checkImageSize(file, true, 300, 200).then(() => {
        this.uploading = true
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            this.uploading = false
            if (response.status === 'success') {
              this.tempImage.push({
                url: response.url
              })
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file)
        this.$message.error(error.message)
      })
    },
    handleRemove (file) {
      var tempId = 0
      this.tempImage.forEach((element, index) => {
        if (element.uid === file.uid) {
          tempId = index
        }
      })
      this.tempImage.splice(tempId, 1)
    },
    dataUpdate: function (data) {
      this.collectionData.items = data
    },
    addBannerItem () {
      this.collectionData.items.push(this.item)
      this.dialogVisible = false
    },
    addBanner () {
      this.item = {
        type: 'banner'
      }
      this.dialogVisible = true
    },
    selectedBanner (banners) {
      this.item.data = banners.find(x => x.id === this.item.data_id)
    },
    onPressUpdateCollection () {
      try {
        if (this.collectionData.end_date === null) {
          delete this.collectionData.end_date
        }
        const tempBody = JSON.parse(JSON.stringify(this.collectionData))
        if (this.tempImage.length > 0) {
          tempBody.collection_pic = this.tempImage[0].url
        } else {
          tempBody.collection_pic = ''
        }
        tempBody.items.forEach(el => {
          delete el.data
        })
        if (this.collectionData.collection_id !== '') {
          this.loading = true
          services.updateCollection(tempBody).then((response) => {
            this.loading = false
            this.alertReporter(
              response.status === 'success' ? 'Амжилттай' : 'Уучлаарай',
              response.message,
              response.status
            )
            if (response.status === 'success') {
              this.isDisabled = false
              this.$router.push({ name: 'collectionList' })
            } else {
              this.alertReporter('Алдаа', response.message, 'error')
            }
          })
        }
      } catch (err) {
        this.alertReporter('Алдаа', err, 'error')
      }
    },
    deleteCollections () {
      this.loading = true
      services.deleteCollection({ collection_id: this.collectionData.collection_id }).then(response => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', `${this.collectionData.collection_id} id- тай collection устлаа`, 'success')
          this.loading = false
          this.$router.push({
            name: 'collectionList'
          })
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', `${this.collectionData.collection_id} id- тай collection устгахад алдаа гарлаа`, 'error')
          this.loading = false
        } else {
          return false
        }
      })
    },
    getOneCollections (id) {
      services.getOneCollection(id).then(response => {
        if (response.status === 'success') {
          this.collectionData = response.data
          this.tempImage.push({ url: response.data.collection_pic })
        }
      })
    },
    getBanner (query) {
      this.loading = true
      services.getBanner(1000, 0, query).then(response => {
        if (response.status === 'success') {
          this.totalCount = response.total
          this.banners = response.data
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },
    back () {
      this.$router.go(-1)
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }
}
</script>
<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
